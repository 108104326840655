<template>
  <div>
    <el-button @click="analyzeShowButton">按钮</el-button>
    <el-table :data="tableData" border stripe :span-method="spanMethod">
      <el-table-column
        prop="name"
        label="费用类目"
        width="180"
      ></el-table-column>
      <el-table-column prop="date" label="小项" width="150"></el-table-column>
      <el-table-column
        prop="remark"
        label="计划备注"
        width="150"
      ></el-table-column>
      <el-table-column
        prop="planMoney"
        label="目标生活费"
        width="150"
      ></el-table-column>
      <el-table-column
        prop="jyMoney"
        label="建议生活费"
        width="150"
      ></el-table-column>
      <el-table-column label="计划生活费">
        <template slot-scope="scope">
          <el-input
            v-model="scope.row.address"
            placeholder="请输入地址"
            size="mini"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column label="实际生活费">
        <template slot-scope="scope">
          <el-input
            v-model="scope.row.address"
            placeholder="请输入地址"
            size="mini"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column label="生活费备注">
        <template slot-scope="scope">
          <el-input
            v-model="scope.row.address"
            placeholder="请输入地址"
            size="mini"
          ></el-input>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      tableData: [
        // 模拟的动态数据
        { date: "2023", name: "12", address: "1212" },
        { date: "2023", name: "12", address: "13121" },
        { date: "2023", name: "12", address: "1212" },
        { date: "2023", name: "98", address: "1212" },
        { date: "2023", name: "32", address: "1212" },
      ],
    };
  },
  methods: {
    spanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        // 只对第一列进行处理
        if (rowIndex > 0) {
          const currentName = row.name;
          const previousRow = this.tableData[rowIndex - 1];
          const previousName = previousRow.name;

          if (currentName === previousName) {
            // 如果当前行的name与上一行相同，则该行的第一列单元格不显示
            return { rowspan: 0, colspan: 0 };
          } else {
            // 如果当前行的name与上一行不同，则计算合并行数
            let rowspan = 1;
            for (let i = rowIndex + 1; i < this.tableData.length; i++) {
              if (this.tableData[i].name === currentName) {
                rowspan++;
              } else {
                break;
              }
            }
            // 设置合并行数
            return { rowspan, colspan: 1 };
          }
        } else {
          // 第一行默认是合并状态，计算合并行数
          let rowspan = 1;
          for (let i = 1; i < this.tableData.length; i++) {
            if (row.name === this.tableData[i].name) {
              rowspan++;
            } else {
              break;
            }
          }
          return { rowspan, colspan: 1 };
        }
      }
    },
    analyzeShowButton() {
      console.log(this.tableData);
    },
  },
};
</script>
 